import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import postStyles from "../styles/post.module.scss";

const NotFoundPage = () => (
  <Layout>
    <div className={postStyles.postContainer}>
      <div className={postStyles.post}>
        <h1>404 NOT FOUND</h1>
        <p>We're sorry, we didn't find what you were looking for.</p>
        <p>Please have a look at the
          <Link to="/countries">Photography Law in all Countries List</Link>.
        </p>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
